module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false,"withWebp":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["si","en"],"defaultLanguage":"si","siteUrl":"https://www.kisik.si","redirect":false,"i18nextOptions":{"defaultNS":"common","supportedLngs":["si","en"],"returnEmptyString":false,"fallbackLng":"si","lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false},"pages":[{"matchPath":"/:lang?/blog/:uid"},{"matchPath":"/:lang?/:lang?/blog/:uid"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LZE2D79BM5","GTM-MT585QTM"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
